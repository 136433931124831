<template>
    <div v-if="visible" class="max-h-statistics overflow-y-scroll overflow-x-hidden">
        <div class="flex mobile:flex-wrap" v-if="isLoading">
            Loading...
        </div>
        <template v-else>
            <div>
                <h2 class="mb-2 text-xl font-semibold">Top Requested</h2>
                <div class="flex flex-wrap justify-between -m-2">
                    <div v-for="entry in most_checked" :key="entry.url" class="w-1/2 p-2">
                        <FakeShop :shop="entry" />
                    </div>
                </div>
            </div>
            <div class="mt-4 pt-3 border-t border-gray-400">
                <h2 class="mb-2 text-xl font-semibold">Newest Fake-Shops</h2>
                <div class="flex flex-wrap justify-between -m-2">
                    <div v-for="entry in last_checked" :key="entry.url" class="w-1/2 p-2">
                        <FakeShop :shop="entry" />
                    </div>
                </div>
            </div>
            <div class="mt-4 pt-3 border-t border-gray-400">
                <h2 class="mb-2 text-xl font-semibold">Latest Requested</h2>
                <div class="flex flex-wrap justify-between -m-2">
                    <div v-for="entry in last_crawled" :key="entry.url" class="w-1/2 p-2">
                        <FakeShop :shop="entry" />
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import $ from 'jquery';
import FakeShop from '../elements/FakeShop';
const axios = require('axios');

export default {
    name: 'Statistics',
    components: {
        FakeShop
    },
    props: {
        visible: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            last_checked: [],
            most_checked: [],
            last_crawled: [],
            isLoading: true
        };
    },
    methods: {
        formatDate(date) {
            const d = new Date(date);
            return d.toLocaleDateString() + ' - ' + d.toLocaleTimeString();
        },
        async statistics(type) {
            try {
                const json = await axios.get('/api/statistics/' + type + '?amount=5');

                if (json.data && Array.isArray(json.data)) {
                    this[type] = json.data.slice(0, 4);
                }
            } catch (e) {
                console.error('Failed to load statistics (' + type + ')', e);
            }
        },
        initialize() {
            const _this = this;

            Promise.all([
                this.statistics('last_checked'),
                this.statistics('most_checked'),
                this.statistics('last_crawled')
            ]).then(() => _this.isLoading = false);
        }
    },
    mounted() {
        const _this = this;
        this.initialize();

        $('body').on('fakeshop.checked', function() {
            _this.initialize();
        });
    }
}
</script>
