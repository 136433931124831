<template>
    <div class="w-screen h-screen bg-cover bg-center" style="background-image: url('../assets/images/stage.jpg')" :class="{'iframe': isIFrame}">
        <div class="absolute top-24 mobile:top-0 w-main left-1/2 transform -translate-x-1/2 bg-white p-5 rounded shadow-lg max-w-full max-h-full overflow-y-auto mobile:w-full mobile:h-full mobile:rounded-none mobile:pt-12 frame">
            <h1 class="text-3xl">Fakeshop Detector <sup class="text-sm relative -top-4 font-semibold">BETA</sup></h1>
            <p class="m-0 text-sm mb-2">
                powered by
                <a href="https://www.trustami.ai" target="_blank" class="text-blue-600">Trustami.AI</a>
            </p>

            <div class="text-sm flex flex-wrap tabs">
                <a href="#" v-for="tab in tabs" :key="tab[0]" class="text-center w-42 p-2 text-black hover:bg-gray-100 border-b-2 border-transparent transition"
                    :class="{ 'border-blue-400': page === tab[0] }" @click="show($event, tab[0])" :title="tab[2]">
                    {{ tab[1] }}
                </a>
            </div>

            <div class="h-px bg-gray-300 -mx-5 mb-4"></div>

            <Main :visible="page === 'main'" />
            <Report :visible="page === 'report'" :hasAcceptedCookies="hasAcceptedCookies" />
            <Statistics :visible="page === 'statistics'" />

            <div class="h-px bg-gray-300 -mx-5 mt-4"></div>

            <div class="text-sm pt-3 text-center">
                <div>
                    <a href="mailto:report@trustami.de" class="text-blue-600" target="_blank">
                        report@trustami.de
                    </a> |
                    <a href="https://www.trustami.ai" class="text-blue-600" target="_blank">
                        Trustami.AI
                    </a> |
                    <a href="https://www.trustami.com" class="text-blue-600" target="_blank">
                        &copy; Trustami GmbH {{ copyright() }}
                    </a>
                </div>
                <div>
                    <a href="https://www.trustami.com/impressum/" class="text-blue-600" target="_blank">
                        Impressum
                    </a> |
                    <a href="https://www.trustami.com/agb/" class="text-blue-600" target="_blank">
                        Allgemeine Geschäftsbedingungen
                    </a> |
                    <a href="https://www.trustami.com/datenschutz/" class="text-blue-600" target="_blank">
                        Datenschutz
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.iframe .frame {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform: none;
    border-radius: 0;
    padding: 1.25rem;
}

.iframe .tabs {
    display: none;
}
</style>

<script>
import Main from './pages/Main';
import Report from './pages/Report';
import Statistics from './pages/Statistics';

export default {
    name: 'Index',
    components: {
        Main,
        Report,
        Statistics
    },
    props: {
        hasAcceptedCookies: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        isIFrame() {
            return window.self !== window.top || window.location.hash === '#iframe';
        }
    },
    data() {
        return {
            page: 'main',

            tabs: [
                ['main', 'Detect', 'Detect if a website is a fake-shop or not'],
                ['report', 'Report', 'Report a new website that is a fake-shop'],
                ['statistics', 'Statistics', 'Statistics of the fake-shop detector']
            ]
        };
    },
    methods: {
        copyright() {
            const year = (new Date()).getUTCFullYear() + '';

            return year === '2021' ? '2021' : '2021 - ' + year;
        },
        show(e, page) {
            e.preventDefault();

            this.page = page;
        }
    }
}
</script>
